<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Registered Keeper
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="addRegisteredKeeper()"
          class="
          flex
          items-center
          bg-green-200
          border
          hover:bg-green-300
          border-green-400
          hover:border-green-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Add Registered Keeper</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap">
      <!-- :line-numbers="true" -->
      <vue-good-table
        v-if="registeredKeepers.length > 0"
        class="w-full"
        styleClass="vgt-table striped"
        mode="remote"
        :rows="registeredKeepers"
        :columns="columns"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_active'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_active == false,
                'bg-green-200 text-green-700': props.row.is_active == true,
              }"
              >{{ props.row.is_active ? "Active" : "Expired" }}</span
            >
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                type="button"
                @click="deleteRegisteredKeeper(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'update:fleets', 'read:fleets']">
              <button
                type="button"
                @click="handleEditRegisteredKeeper(props)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="registeredKeepers.length == 0">
        There are no Registered Keeper(s) for this Fleet.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditRegisteredKeeperPanelOpen"
        @close="addEditRegisteredKeeperPanelOpen = false"
        :title="editingRegisteredKeeper.regkeeper_id ? 'Edit Registered Keeper' : 'Add Registered Keeper'"
      >
        <AddEditRegisteredKeeper :registeredKeeper="editingRegisteredKeeper" @complete="saveRegisteredKeeper" @delete="deleteRegisteredKeeper" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message" @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddEditRegisteredKeeper = () => import("@/components/Fleets/Edit/AddEditRegisteredKeeper.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "RegisteredKeeper",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditRegisteredKeeper,
    IsAuthorized,
  },
  props: {
    registeredKeepers: Array,
  },
  data() {
    return {
      addEditRegisteredKeeperPanelOpen: false,
      editingRegisteredKeeper: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Name",
          field: "name",
          width: "20%",
        },
        {
          label: "Address",
          field: "address1",
          width: "20%",
        },
        {
          label: "Postcode",
          field: "postcode",
          width: "20%",
        },
        {
          label: "Country",
          field: "country",
          width: "20%",
        },
        // {
        //   label: " ",
        //   field: "is_active",
        //   width: "100",
        //   tdClass: this.tblClassCenterAlign,
        //   thClass: this.tblClassCenterAlign,
        // },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },
  computed: {
    fleet_id: function() {
      return this.$store.state.lastSelectedFleet;
    },
  },
  methods: {
    tblClassLeftAlign(row) {
      return "vgt-left-align";
    },
    tblClassCenterAlign(row) {
      return "vgt-center-align";
    },
    addRegisteredKeeper: function() {
      this.editingRegisteredKeeper = {};
      this.addEditRegisteredKeeperPanelOpen = true;
    },
    handleEditRegisteredKeeper: function(selectedRow) {
      let registeredKeeperData = selectedRow.row;
      this.editRegisteredKeeper(registeredKeeperData);
    },
    editRegisteredKeeper: function(registeredKeeper) {
      this.editingRegisteredKeeper = { ...registeredKeeper };
      this.addEditRegisteredKeeperPanelOpen = true;
    },
    saveRegisteredKeeper: async function(registeredKeeper) {
      // Ensure registered keepers has mandatory fields filled out
      let msg;
      if (!registeredKeeper.name) {
        msg = "Please enter name.";
      } else if (!registeredKeeper.address1) {
        msg = "Please provide address1.";
      } else if (!registeredKeeper.postcode) {
        msg = "Please provide postcode.";
      } else if (!registeredKeeper.country) {
        msg = "Please provide country.";
      }

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditRegisteredKeeperPanelOpen = false;

      try {
        if (!registeredKeeper.regkeeper_id) {
          let createResult = await this.FleetService.createFleetRegisteredKeeper(this.fleet_id, registeredKeeper);
          registeredKeeper.regkeeper_id = createResult.data.regkeeper_id;
          this.registeredKeepers.push(registeredKeeper);
        } else {
          await this.FleetService.updateFleetRegisteredKeeper(this.fleet_id, registeredKeeper.regkeeper_id, registeredKeeper);
          let idx = this.$_.findIndex(this.registeredKeepers, (c) => c.regkeeper_id == registeredKeeper.regkeeper_id);
          this.registeredKeepers.splice(idx, 1, registeredKeeper);
        }

        this.$emit("complete");

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Registered Keeper details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);
        let msg = !registeredKeeper.regkeeper_id
          ? "There was a problem creating the new Registered Keeper"
          : "There was a problem updating the Registered Keeper";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handleDeleteRegisteredKeeper: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.FleetService.deleteRegisteredKeeper(this.fleet_id, this.confirmAlert.data.regkeeper_id);
          let idx = this.$_.findIndex(this.registeredKeepers, (c) => {
            return c.regkeeper_id == this.confirmAlert.data.regkeeper_id;
          });

          this.$delete(this.registeredKeepers, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Registered Keeper Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted Registered Keeper"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the Registered Keeper"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.isBusy = false;
        this.addEditAddEditRegisteredKeeperPanelOpen = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteRegisteredKeeper: function(registeredKeeper) {
      // If we're deleting a registeredKeeper that hasn't been saved yet
      if (String(registeredKeeper.regkeeper_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.registeredKeepers, (c) => {
          return c.regkeeper_id == registeredKeeper.regkeeper_id;
        });

        this.$delete(this.registeredKeepers, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteRegisteredKeeper,
        message: `Are you sure you wish to delete '${registeredKeeper.name}' This cannot be undone.`,
        showing: true,
        data: registeredKeeper,
      };
    },
  },
};
</script>
